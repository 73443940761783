export const teamDataObj = {
  id: "team",
  memberData: [
    {
      name: "Philipp Ljubarskij",
      title: "President, Software Team",
      image: require("../../images/team/bw/Philipp.JPG"),
      description: "Master Biomedical Systems Engineering at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Fatih Kaya",
      title: "Vice President, Hardware Team",
      image: require("../../images/team/bw/Fatih.jpg"),
      description: "Master Electrical Engineering - Systems and Automation at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "http://linkedin.com/in/fatih-kaya-a80098256",
      },
    },
    {
      name: "Ivan Karetić",
      title: "Hardware Team",
      image: require("../../images/team/bw/Ivan.JPG"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Krittapas Boonpasart",
      title: "Social Media Team",
      image: require("../../images/team/bw/KrisNew.jpg"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Josef Bolten",
      title: "Public Relations Team",
      image: require("../../images/team/bw/Josef.JPG"),
      description: "Master in Robotics at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Poojitha Reddy Nellipudi ",
      title: "Hardware Team",
      image: require("../../images/team/bw/Poojitha.JPG"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Maroof Abdul Aziz",
      title: "Hardware Team",
      image: require("../../images/team/bw/Maroof.JPG"),
      description: "Master in Robotics at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    
    {
      name: "Nnanna Muoneke",
      title: "Social Media Team",
      image: require("../../images/team/bw/Nnanna.JPG"),
      description: "Master in Robotics at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Zeyneb Güven",
      title: "Software Team",
      image: require("../../images/team/bw/Zeyneb.jpg"),
      description: "Bachelor Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "https://www.linkedin.com/in/zeyneb-g%C3%BCven-4186a3232/",
      },
    },
    {
      name: "Ahmet Dursun Umut Özdemir",
      title: "Hardware Team",
      image: require("../../images/team/bw/Umut.jpeg"),
      description: "Bachelor Electrical Engineering and Information at RWTH Aachen",
      socials: {
        github: "https://github.com/adumut",
        linkedIn: "https://de.linkedin.com/in/ahmet-dursun-umut-%C3%B6zdemir-687a821b5",
      },
    },
    {
      name: "Zilvan Mondry",
      title: "Hardware Team",
      image: require("../../images/placeholder-image.jpeg"),
      description: "Bachelor Electrical Engineering at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Amarachi Vazidlule",
      title: "Software Team",
      image: require("../../images/placeholder-image.jpeg"),
      description: "Bachelor Molecular Biotechnology at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    }
  ],
  redBgPoint: require("../../images/square.png"),
  alumniData: [
    {
      name: "Mohamed Alhaskir",
      title: "Founder, Software Team",
      image: require("../../images/team/bw/Mo.JPG"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    
    {
      name: "Shivam Singh Rajput",
      title: "President",
      image: require("../../images/team/bw/Shivam.JPG"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Akhil Verma",
      title: "Hardware Team",
      image: require("../../images/team/bw/Akhil.JPG"),
      description: "Master in Robotics at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Dimitar Boev",
      title: "Vice President",
      image: require("../../images/placeholder-image.jpeg"),
      description: "Master Computer Science at RWTH Aachen",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    {
      name: "Siddharth Singh",
      image: require("../../images/placeholder-image.jpeg"),
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },
    /*,
    {
      name: "Siddharth Singh",
      socials: {
        github: "#",
        linkedIn: "#",
      },
    },*/
  ],
};
